<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <!-- <v-col cols="4"></v-col> -->

      <v-img
        cols="4"
        max-width="300px"
        @click="signInWithGoogle"
        alt=" Sign in with Google"
        src="../assets/btn_google_signin_dark_pressed_web.png"
      />

      <!-- <v-col cols="4"></v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  data: () => ({
    show: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    rules: {
      required: (value) => !!value || "Required",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  computed: mapState(["site", "config", "user"]),
  methods: mapActions(["submitLogin", "signInWithGoogle"]),
};
</script>
